import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Button,
  TextField,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import PrintComponents from "react-print-components";
import QRCode from "qrcode.react";
import Loading from "../Components/Loading/Loading";
import { getLivePOsDDL, getQRDataForReprint } from "../../Redux/API/api_Common";

import FormControl from "@material-ui/core/FormControl";
import "./ReprintQR.scss";

import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

import { getMLFBProducts } from "../../Redux/API/api_MlfbMaster";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5),
    display: "flex",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  snackbarRoot: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    minWidth: 150,
  },
}));


export default function ReprintQR() {
  const classes = useStyles();
  const componentRef = useRef();
  const [isSmallPO, setIsSmallPO] = useState(false);
  const [po, setPO] = useState("");
  const [poObj, setPOObj] = useState("");
  const [poText, setPOText] = useState("");
  const siteToken = useSelector((state) => state.saveTokenReducer);
   const azureToken = useSelector((state) => state.saveAzureTokenReducer);
   const userdetails = useSelector(
     (state) => state.storeUserDetailsReducer.userData
   );
   const UID = useSelector((state) => state.saveUserIdReducer);
   const [qrData, setQRData] = useState([]);
   let [polarityData, setPolarityData] = useState({});
   const [isLoader, setIsLoader] = useState(false);
   const [loaderText, setLoaderText] = useState("Please wait....");
   let [today] = useState(new Date());

   const [poData, setPOData] = useState([]);

   const handleChangePO = (event, newValue) => {
     setPOObj(newValue);
     setPO(newValue ? newValue.id : 0);
   };
   const handleChangePOInput = (event, newInputValue) => {
     setPOText(newInputValue);
   };

   const [open, setOpen] = React.useState(false);
   const [snackMessage, setSnackMessage] = React.useState(
     "PD Reject Successfull"
   );
   const [state, setState] = React.useState({
     vertical: "top",
     horizontal: "center",
   });
   const { vertical, horizontal } = state;

   const handleClose = (event, reason) => {
     setOpen(false);
   };

   const handleSnackOpen = (text) => {
     setSnackMessage(text);
     setOpen(true);
     setState({
       vertical: "bottom",
       horizontal: "center",
     });
   };

   const generateQRData = () => {
     setLoaderText("Generating QR....");
     setIsLoader(true);
     getQRDataForReprint(
       siteToken,
       po,
       UID.id,
       userdetails.userEmail,
       userdetails.gid,
       userdetails.roleName,
       userdetails.roleId,
       azureToken.token
     )
       .then((response) => {
         if (response.response) {
           setQRData(response.objResult.qrData);
           setPolarityData(response.objResult.polarityData);
           setIsLoader(false);
         } else {
           setIsLoader(false);
         }
       })
       .catch((error) => {
         console.log("Error: " + error, "error");
         setIsLoader(false);
       });
   };
   const [itemsPO, setItemsPO] = useState([]);
   function getLivePOs() {
     setIsLoader(true);
     getLivePOsDDL(
       siteToken,
       UID.id,
       userdetails.userEmail,
       userdetails.gid,
       userdetails.roleName,
       userdetails.roleId,
       azureToken.token
     )
       .then((response) => {
         setPOData(response);
         setItemsPO(response.map((item) => ({ id: item.id, name: item.name })));
         setIsLoader(false);
       })
       .catch((error) => {
         console.log("Error: " + error, "error");
         setIsLoader(false);
       });
   }
   const toggleChange = (e) => {
     setIsSmallPO(e.target.checked);
     let filterdPOs = [];
     if (e.target.checked) {
       if (productId) {
         filterdPOs = poData.filter(
           (x) => x.isSmallPO === e.target.checked && x.productId === productId
         );
       } else {
         filterdPOs = poData.filter((x) => x.isSmallPO === e.target.checked);
       }
     } else {
       if (productId) {
         filterdPOs = poData.filter(
           (x) =>
             (x.isSmallPO === e.target.checked || x.isSmallPO === null) &&
             x.productId === productId
         );
       } else {
         filterdPOs = poData.filter(
           (x) => x.isSmallPO === e.target.checked || x.isSmallPO === null
         );
       }
     }
     setItemsPO(filterdPOs.map((item) => ({ id: item.id, name: item.name })));
   };

   const [productId, setProductId] = useState("");
   const [productsData, setProductsData] = useState([]);
   async function getProductDetails() {
     setIsLoader(true);
     const response = await getMLFBProducts(
       siteToken,
       userdetails.userEmail,
       userdetails.gid,
       userdetails.roleName,
       UID.id,
       userdetails.roleId,
       azureToken.token
     );
     const productItems = response.map((item) => ({
       value: item.id,
       label: item.name,
     }));
     setProductsData(productItems);
     setIsLoader(false);
   }

  const handleChangeProduct = (e) => {
    setProductId(e.target.value);
    let filterdPOs = [];
    if (isSmallPO) {
      filterdPOs = poData.filter(
        (x) => x.isSmallPO === isSmallPO && x.productId === e.target.value
      );
    } else {
      filterdPOs = poData.filter(
        (x) =>
          x.productId === e.target.value &&
          (x.isSmallPO === e.target.checked || x.isSmallPO === null)
      );
    }
    setItemsPO(filterdPOs.map((item) => ({ id: item.id, name: item.name })));
  };

  const clickResetFilter = (e) => {
    setProductId("");
    setIsSmallPO(false);
    setItemsPO(poData.map((item) => ({ id: item.id, name: item.name })));
  };
  useEffect(() => {
    getLivePOs();
    getProductDetails();
  }, [userdetails]);

  return (
    <>
      <div className={classes.snackbarRoot}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message={snackMessage}
          autoHideDuration={2000}
          key={vertical + horizontal}
          role="alert"
        />
      </div>

      <div className="QR-code-section">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} className="text-left">
            <h3>Reprint QR</h3>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className="text-right clr-red">
            <p>
              Disclaimer: QR can be reprinted for dispatched production orders
              only.
            </p>
          </Grid>
        </Grid>
        <div style={{ display: "flex" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>Regular PO</Grid>
                      <Grid item>
                        <Switch checked={isSmallPO} onChange={toggleChange} />
                      </Grid>
                      <Grid item>Small PO</Grid>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-controlled-open-select-label">
                      Products
                    </InputLabel>
                    <Select value={productId} onChange={handleChangeProduct}>
                      {productsData.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    className="pt-button--primary"
                    onClick={clickResetFilter}
                  >
                    Reset Filter
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                </Grid>
                <Grid item xs={12} sm={6}>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div style={{ display: "flex", marginTop: "1.5rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                loading
                id="reprint-qr"
                freeSolo
                options={itemsPO}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                inputValue={poText}
                value={poObj}
                onChange={handleChangePO}
                onInputChange={handleChangePOInput}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Enter Production Order Number"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                className="pt-button--primary generate-qrcode-btn"
                onClick={generateQRData}
                disabled={!po}
              >
                Generate QR Code
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* QR Grid */}
      {qrData && qrData.length > 0 ? (
        <div>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <PrintComponents
                trigger={
                  <div>
                    <Button
                      className="pt-button--secondary pt-button--print-QRCode QR-Code-print"
                      style={{ margin: "12px 0 20px 16px" }}
                    >
                      Print QR Code
                    </Button>
                  </div>
                }
              >
                {qrData.map((item, index) => (
                  <div
                    style={{
                      padding: "20px 20px 50px 0px",
                      width: "100%",
                    }}
                    id={index}
                  >
                    <Grid
                      container
                      spacing={3}
                      ref={componentRef}
                      className={classes.pagePrint}
                    >
                      <table
                        style={{
                          padding: "2cm 2.5cm 0cm",
                        }}
                      >
                        <tbody>
                          <tr style={{ display: "flex" }}>
                            <td
                              style={{
                                borderRight: "1px solid #333333",
                                borderLeft: "1px solid #333333",
                                borderTop: "1px solid #333333",
                                borderBottom: "1px solid #333333",
                              }}
                            >
                              <div
                                className="d-flex"
                                style={{
                                  width: "450px",
                                  fontSize: "30px",
                                }}
                              >
                                <span
                                  style={{
                                    padding: "7px 14px",
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    borderRight: "1px solid #333333",
                                  }}
                                >
                                  Invoice No.
                                </span>
                                <span
                                  style={{
                                    padding: "7px 10px",
                                  }}
                                >
                                  {item.supplierInvoiceNumber}
                                </span>
                              </div>
                              <div
                                className="d-flex"
                                style={{
                                  width: "450px",
                                  fontSize: "30px",
                                  borderTop: "1px solid #333333",
                                }}
                              >
                                <span
                                  style={{
                                    padding: "7px 14px",
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    borderRight: "1px solid #333333",
                                  }}
                                >
                                  Date
                                </span>
                                <span
                                  style={{
                                    padding: "7px 14px",
                                  }}
                                >
                                  {item.dispatchDate
                                    .toUpperCase()
                                    .replace(",", "")
                                    .includes("01 JAN 0001")
                                    ? ""
                                    : item.dispatchDate
                                      .toUpperCase()
                                      .replace(",", "")}
                                </span>
                              </div>
                              <div
                                className="d-flex"
                                style={{
                                  width: "450px",
                                  fontSize: "30px",
                                  borderTop: "1px solid #333333",
                                }}
                              >
                                <span
                                  style={{
                                    padding: "7px 14px",
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    borderRight: "1px solid #333333",
                                  }}
                                >
                                  MLFB
                                </span>
                                <span
                                  style={{
                                    padding: "7px 14px",
                                  }}
                                >
                                  {item.mlfb}
                                </span>
                              </div>
                              <div
                                className="d-flex"
                                style={{
                                  width: "450px",
                                  fontSize: "30px",
                                  borderTop: "1px solid #333333",
                                }}
                              >
                                <span
                                  style={{
                                    padding: "7px 14px",
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    borderRight: "1px solid #333333",
                                  }}
                                >
                                  Rating
                                </span>
                                <span
                                  style={{
                                    padding: "7px 14px",
                                  }}
                                >
                                  {item.rating}
                                </span>
                              </div>
                              <div
                                className="d-flex"
                                style={{
                                  width: "450px",
                                  fontSize: "30px",
                                  borderTop: "1px solid #333333",
                                  letterSpacing: "-2px",
                                }}
                              >
                                <span
                                  style={{
                                    padding: "7px 14px",
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    borderRight: "1px solid #333333",
                                  }}
                                >
                                  Packing
                                </span>
                                <span
                                  style={{
                                    padding: "7px 14px",
                                  }}
                                >
                                  {item.packingType}
                                </span>
                              </div>
                              <div
                                className="d-flex"
                                style={{
                                  width: "450px",
                                  fontSize: "30px",
                                  borderTop: "1px solid #333333",
                                }}
                              >
                                <span
                                  style={{
                                    padding: "7px 14px",
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    borderRight: "1px solid #333333",
                                  }}
                                >
                                  Prod O.
                                </span>
                                <span
                                  style={{
                                    padding: "7px 14px",
                                  }}
                                >
                                  {item.productionOrderNumber}
                                </span>
                              </div>
                              <div
                                className="d-flex"
                                style={{
                                  width: "450px",
                                  fontSize: "30px",
                                  borderTop: "1px solid #333333",
                                }}
                              >
                                <span
                                  style={{
                                    padding: "7px 14px",
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    borderRight: "1px solid #333333",
                                  }}
                                >
                                  Polarity
                                </span>
                                <span
                                  style={{
                                    padding: "7px 14px",
                                  }}
                                >
                                  {item.polarity}
                                </span>
                              </div>
                              <div
                                className="d-flex"
                                style={{
                                  width: "450px",
                                  fontSize: "30px",
                                  borderTop: "1px solid #333333",
                                }}
                              >
                                <span
                                  style={{
                                    padding: "7px 14px",
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    borderRight: "1px solid #333333",
                                  }}
                                >
                                  Quantity
                                </span>
                                <span
                                  style={{
                                    padding: "7px 14px",
                                  }}
                                >
                                  {item.packedOk}
                                </span>
                              </div>
                            </td>
                            <td
                              style={{
                                borderRight: "1px solid #333333",
                                borderLeft: "0px solid #333333",
                                borderTop: "1px solid #333333",
                                borderBottom: "1px solid #333333",
                              }}
                            >
                              <div
                                className="QR-Code"
                                style={{
                                  padding: "12px 20px 0 36px",
                                  marginTop: "7px",
                                }}
                              >
                                <QRCode
                                  value={JSON.stringify(item)}
                                  size={200}
                                />
                              </div>
                              <div style={{ padding: "12px 20px 0 36px" }}>
                                <h4 style={{ margin: "8px 0 0" }}>
                                  Printed on:{" "}
                                  <span>{today.toDateString()}</span>
                                </h4>
                              </div>

                              <div style={{ padding: "0 8px 16px" }}>
                                <p>
                                  {" "}
                                  <strong>
                                    &#64;{item.supplierName.toUpperCase()}{" "}
                                  </strong>
                                </p>
                              </div>

                              <div
                                className="d-flex jc-space-bt"
                                style={{
                                  borderTop: "1px solid #000000",
                                  borderBottom: "1px solid #000000",
                                  margin: "12px 0 0",
                                  padding: "0 8px 0",
                                }}
                              >
                                <p
                                  className="location"
                                  style={{
                                    padding: "8px 16px",
                                    fontSize: "30px",
                                    borderRight: "1px solid",
                                    margin: "0",
                                  }}
                                >
                                  {item.location1}
                                </p>
                                <p
                                  className="location"
                                  style={{
                                    padding: "8px 16px",
                                    fontSize: "30px",
                                    borderRight: "1px solid",
                                    margin: "0",
                                  }}
                                >
                                  {item.location2}
                                </p>
                                <p
                                  className="location"
                                  style={{
                                    padding: "8px 16px",
                                    fontSize: "30px",
                                    margin: "0",
                                  }}
                                >
                                  {item.location3}
                                </p>
                              </div>
                              <div style={{ padding: "0 16px" }}>
                                <p
                                  style={{ fontSize: "30px", lineHeight: "0" }}
                                >
                                  {item.materialNumber}
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </div>
                ))}
              </PrintComponents>
            </Grid>
          </Grid>

          {/* On Screen Data */}
          <Grid container spacing={3}>
            {qrData.map((item, index) => (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Paper className={classes.paper} id={index}>
                  <Grid
                    container
                    spacing={3}
                    ref={componentRef}
                    className={classes.pagePrint}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={7}
                      className="QR-Code-details"
                    >
                      <div className="d-flex jc-space-bt">
                        <span>Invoice No.</span>
                        <span className="text-right">
                          {item.supplierInvoiceNumber}
                        </span>
                      </div>
                      <div className="d-flex jc-space-bt">
                        <span>Date</span>
                        <span className="text-right">
                          {item.dispatchDate
                            .toUpperCase()
                            .replace(",", "")
                            .includes("01 JAN 0001")
                            ? ""
                            : item.dispatchDate.toUpperCase().replace(",", "")}
                        </span>
                      </div>
                      <div className="d-flex jc-space-bt">
                        <span>MLFB</span>
                        <span>{item.mlfb}</span>
                      </div>
                      <div className="d-flex jc-space-bt">
                        <span>Rating</span>
                        <span>{item.rating}</span>
                      </div>
                      <div className="d-flex jc-space-bt">
                        <span>Packing</span>
                        <span>{item.packingType}</span>
                      </div>
                      <div className="d-flex jc-space-bt">
                        <span>Prod. Order No.</span>
                        <span>{item.productionOrderNumber}</span>
                      </div>
                      <div className="d-flex jc-space-bt">
                        <span>Polarity</span>
                        <span>{item.polarity}</span>
                      </div>
                      <div className="d-flex jc-space-bt">
                        <span>Quantity</span>
                        <span>{item.packedOk}</span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={5}
                      className="QR-Code-block d-flex jc-center"
                    >
                      <div className="QR-Code">
                        <QRCode
                          value={JSON.stringify(item)}
                          size={200}
                          includeMargin={true}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <></>
      )}
      {isLoader ? <Loading loaderText={loaderText} /> : <></>}
    </>
  );
}
